@import 'styles/variables';
@import 'styles/extensions';

.main {
  width: 100%;

  @media (min-width: 2560px) {
    overflow: initial;
  }

  @media screen and (max-width: 767px) {
    overflow: hidden;
  }  

  &__wrapper {
    @extend %wrapper;
    overflow: hidden;

    @media (min-width: 2560px) {
      max-width: calc(1280px * #{$zoom});
    }
  }

  &__heading {
    position: relative;
    height: 100%;
    min-height: 1500px;

    @media (min-width: 2560px) {
      height: calc(100% * #{$zoom});
      min-height: calc(1200px * #{$zoom});
    }

    @media screen and (max-width: 450px) {
      height: 140vh;
      min-height: 1350px;
    }

    h1 {
      padding-bottom: 140px;
      font-weight: normal;
      font-size: 96px;
      font-family: $secondary-font;
      line-height: 104px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: uppercase;

      span {
        color: $light-blue;
      }

      @media (min-width: 2560px) {
        font-size: calc(96px * #{$zoom});
        line-height: calc(104px * #{$zoom});
      }

      @media screen and (max-width: 1200px) {
        font-size: 8vw;
        line-height: 8.5vw;
      }

      @media screen and (max-width: 768px) {
        padding-bottom: calc(75vw + 80px);
        font-size: 60px;
        line-height: 64px;
      }

      @media screen and (max-width: 450px) {
        font-size: 40px;
        line-height: 48px;
      }
    }

    p {
      max-width: 480px;
      margin-bottom: 45px;

      @media (min-width: 2560px) {
        max-width: calc(480px * #{$zoom});
        font-size: calc(24px * #{$zoom});
        line-height: calc(28px * #{$zoom});
      }

      @media screen and (max-width: 768px) {
        min-width: 100%;
      }
    }
  }

  &__outter-bg {
    position: relative;
    max-width: 1340px;
    margin: 0 auto;

    @media (min-width: 2560px) {
      max-width: calc(1340px * #{$zoom});
    }

    &::after {
      position: absolute;
      top: 10%;
      left: 32%;
      z-index: -2;
      width: 53%;
      height: 25%;
      background: linear-gradient(118.54deg, #10096D 17.74%, #1C3E66 55.53%, #467B94 84.72%, #BDEAF4 97.33%);
      border-radius: 200px;
      transform: rotate(13.04deg);
      filter: blur(100px);
      content: '';

      @media (min-width: 2560px) {
        filter: blur(calc(100px * #{$zoom}));
      }
    }
    
    &::before {
      position: absolute;
      top: 52%;
      left: 39%;
      z-index: -2;
      width: 50%;
      height: 30%;
      background: linear-gradient(118.54deg, #272538 17.74%, #203752 55.53%, #275063 84.72%, #53C2DD 97.33%);
      border-radius: 200px;
      transform: rotate(-27.04deg);
      filter: blur(100px);
      content: '';

      @media screen and (max-width: 450px) {
        top: 150px;
        left: 10%;
        width: 100%;
        height: 50%;
      }

      @media (min-width: 2560px) {
        filter: blur(calc(100px * #{$zoom}));
      }
    }
  }

  &__inner-bg {
    position: relative;  

    &::before {
      position: absolute;
      top: 5%;
      left: 23%;
      z-index: -1;
      width: 20%;
      height: 20%;
      background-image: url('../../../asset/resource/dahsed-title-line.png');
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100%;
      content: '';

      @media screen and (max-width: 450px) {
        top: 6%;
        left: 0%;
        width: 40%;
        height: 40%;
      }
    }

    &::after {
      position: absolute;
      top: 55%;
      left: -3%;
      z-index: -1;
      width: 50%;
      height: 50%;
      background-image: url('../../../asset/resource/dahsed-title-line-two.png');
      background-repeat: no-repeat;
      background-position: fixed;
      background-size: 100%;
      content: '';

      @media screen and (max-width: 450px) {
        top: 35%;
        left: -40%;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__heading-title {
    position: absolute;
    z-index: 1;
    max-width: 600px;   
    margin-left: 2%;      
    padding-top: 350px;

    @media (min-width: 2560px) {
      max-width: calc(600px * #{$zoom});
    }

    @media screen and (max-width: 1200px) {
      max-width: 50vw;
    }

    @media screen and (max-width: 768px) {
      position: relative;
      max-width: 520px;
      padding-top: 170px;
    }

    @media screen and (max-width: 475px) {
      margin-right: 20px;
      margin-left: 20px;  
    }
  }

  &__heading-description {
    font-weight: 800;
    font-size: 32px;
    font-family: $secondary-font;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: left;

    @media (min-width: 2560px) {
      font-size: calc(32px * #{$zoom});
      line-height: calc(36px * #{$zoom});
    }

    @media screen and (max-width: 450px) {
      font-size: 27px;
      line-height: 30px;
    }
  }

  &__inview {
    position: -webkit-sticky;
    position: sticky;
    top: 15%;
    right: 0;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;

    
    @media screen and (max-width: 768px) {
      position: absolute;
      top: calc(20% + 60px);
      left: 50%;
      justify-content: center;
      transform: translateX(-50%);
    }

    @media screen and (max-width: 450px) {
      top: calc(21% + 60px);
    }
  }

  &__video-content {
    position: relative;
    width: 640px;
    margin-right: 4%;
    transform: rotate(10deg);

    @media (min-width: 2560px) {
      width: calc(600px * #{$zoom});
      margin-right: 0;
    }

    @media screen and (max-width: 1300px) {
      max-width: 500px;
      margin-right: 6%;
    }

    @media screen and (max-width: 768px) {
      max-width: 75vw;
    }
  }

  &__how-it-works {
    padding-right: 0;
    padding-bottom: 200px;
    padding-left: 0;
    overflow: hidden;

    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
      padding-bottom: calc(200px * #{$zoom});
      overflow: inherit;
    }

    @media screen and (max-width: 767px) {
      overflow: inherit;
    }
  }

  &__how-it-works h2,
  &__how-to-use h2,
  &__use-cases h2,
  &__challenge h2 {
    @extend %wrapper;
    max-width: 1140px;
    padding-right: 0;
    padding-bottom: 90px;
    padding-left: 0;
    font-weight: 800;
    font-size: 60px;
    font-family: $secondary-font;
    line-height: 64px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
      padding-bottom: calc(90px * #{$zoom});
      font-size: calc(60px * #{$zoom});
      line-height: calc(64px * #{$zoom});
    }

    @media screen and (max-width: 767px) {
      font-size: 48px;
      line-height: 56px;
    }

    @media screen and (max-width: 450px) {
      padding-bottom: 70px;
      font-size: 32px;
      line-height: 36px;
    }
  }

  &__how-it-works-blocks {
    max-width: 860px;  
    margin: 0 auto;

    @media (min-width: 2560px) {
      max-width: calc(860px * #{$zoom});
    }
  }

  &__how-it-works-block {
    position: relative;
    max-width: 480px;
    margin-top: 5px;
    margin-bottom: -25px;
    padding-bottom: 38px;
    background: linear-gradient(90deg, rgba(0, 163, 207, 0.2) 0%, rgba(85, 82, 160, 0.2) 100%);
    border: 4px solid #396D98;
    box-shadow: 0px 4px 24px rgba(255, 255, 255, 0.16);
    transition: margin-bottom ease-in-out .2s;

    &:nth-child(even) {
      margin-left: auto;
    }

    figcaption {
      padding: 0 32px; 

      @media (min-width: 2560px) {
        padding: 0 calc(32px * #{$zoom});
        font-size: calc(16px * #{$zoom});
      }

      span {
        color: $light-blue;
        font-weight: bold;
        font-size: 20px;
        line-height: 36px;

        @media (min-width: 2560px) {
          font-size: calc(20px * #{$zoom});
          line-height: calc(36px * #{$zoom});
        }
      }
    }

    @media (min-width: 2560px) {
      max-width: calc(480px * #{$zoom});
      margin-top: calc(5px * #{$zoom});
      margin-bottom: calc(-25px * #{$zoom});
      padding-bottom: calc(38px * #{$zoom});
    }    

    @media screen and (max-width: 767px) {
      margin-bottom: 25px;
    }

    &_one {      
      &::before {
        position: absolute;
        top: -280px;
        left: -50%;
        z-index: -1;
        width: 100%;
        height: 100%;
        background-image: url('../../../asset/resource/dahsed-title-line-two.png');
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100%;
        transform: rotate(67deg);
        content: '';
        transition: all 0.3s ease-in-out;

        @media (min-width: 2560px) {
          top: calc(-430px * #{$zoom});
          left: calc(-65% * #{$zoom});
          width: calc(100% * #{$zoom});
          height: calc(100% * #{$zoom});
        }

        @media screen and (max-width: 767px) {
          left: -96%;
        }
      }
    }
    
    &_two {      
      &::before {
        position: absolute;
        top: -280px;
        left: 50%;
        z-index: -1;
        width: 91%;
        height: 100%;
        background-image: url('../../../asset/resource/dahsed-title-line-two.png');
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100%;
        transform: rotate(188deg);
        content: '';
        transition: all 0.3s ease-in-out;

        @media (min-width: 2560px) {
          top: calc(-400px * #{$zoom});
          left: calc(45% * #{$zoom});
          width: calc(91% * #{$zoom});
          height: calc(100% * #{$zoom});
        }

        @media screen and (max-width: 767px) {
          top: -335px;
          left: 56%;
          transform: rotate(171deg);
        }
      }
    }

    &_three{      
      &::before {
        position: absolute;
        top: -404px;
        left: -74%;
        z-index: -1;
        width: 100%;
        height: 100%;
        background-image: url('../../../asset/resource/dahsed-title-line-two.png');
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100%;
        transform: rotate(98deg);
        content: '';
        transition: all 0.3s ease-in-out;

        @media (min-width: 2560px) {
          top: calc(-566px * #{$zoom});
          left: calc(-75% * #{$zoom});
          width: calc(100% * #{$zoom});
          height: calc(100% * #{$zoom});
        }

        @media screen and (max-width: 767px) {
          top: -427px;
          left: -54%;
          transform: rotate(108deg);
        }
      }
    }

    &_four {      
      &::before {
        position: absolute;
        top: -328px;
        left: 77%;
        z-index: -1;
        width: 100%;
        height: 131%;
        background-image: url('../../../asset/resource/dahsed-title-line-two.png');
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100%;
        transform: rotate(180deg);
        content: '';
        transition: all 0.3s ease-in-out;

        @media (min-width: 2560px) {
          top: calc(-400px * #{$zoom});
          left: calc(55% * #{$zoom});
          width: calc(86% * #{$zoom});
          height: calc(105% * #{$zoom});
        }

        @media screen and (max-width: 767px) {
          top: -426px;
          left: 48%;
          transform: rotate(146deg);
        }
      }
    }

    &::before {
      @media screen and (max-width: 475px) {
        content: none!important;
      }
    }    
  }

  .main__how-it-works-blocks > div {
    &:nth-child(1),
    &:nth-child(3) {
      .main__how-it-works-block {
        transform: translatex(-150vw);
        
        @media (min-width: 2560px) {
          transform: translatex(calc(-200vw * #{$zoom}))!important;
        }
      }

      .main__how-it-works-block-done {
        transform: translatex(0)!important;
        transition: transform 1s ease, box-shadow 0.2s ease; 
      }
    }

    &:nth-child(2),
    &:nth-child(4) {
      .main__how-it-works-block {
        transform: translatex(200vw);
        
        @media (min-width: 2560px) {
          transform: translatex(calc(200vw * #{$zoom}))!important;
        }
      }

      .main__how-it-works-block-done {
        margin-left: auto;
        transform: translatex(0)!important;
        transition: transform 1s ease, box-shadow 0.2s ease;

        @media (min-width: 2560px) {
          transform: translatex(calc(400px * #{$zoom}))!important;
        }

        @media screen and (min-width: 1024px) {
          margin-left: initial;
          transform: translatex(400px)!important;
        }
      }
    }
  } 

  &__how-to-use h3,
  &__use-cases-slide h3 {
    @extend %wrapper;
    max-width: 1140px;
    padding-right: 0;
    padding-bottom: 35px;
    padding-left: 0;
    font-weight: 800;
    font-size: 40px;
    font-family: $secondary-font;
    line-height: 48px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
      padding-bottom: calc(35px * #{$zoom});
      font-size: calc(40px * #{$zoom});
      line-height: calc(48px * #{$zoom});
    }

    @media screen and (max-width: 767px) {
      font-size: 32px;
      line-height: 36px;
    }

    @media screen and (max-width: 450px) {
      font-size: 24px;
      line-height: 24px;
    }
  }

  &__how-to-use-blocks {
    max-width: 1140px;
    margin: 0 auto;

    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
    }
  }

  &__how-to-use-block {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 250px;

    div {
      flex-basis: 45%;
    }

    @media (min-width: 2560px) {
      margin-bottom: calc(250px * #{$zoom});
    }

    &:nth-child(even) {
      flex-direction: row-reverse;

      figure {
          img {
            justify-self: right;

            @media (max-width: 767px) {
              justify-self: center;
            }
        }
      }

      @media (max-width: 767px) {
        flex-direction: column;
      }
    }

    article,
    figure {
      flex-basis: 45%;
    }

    figure {
      display: grid;

      img {
        align-self: center;
        justify-self: left;
        width: 100%;
        max-width: 370px;

        @media (min-width: 2560px) {
          max-width: calc(370px * #{$zoom});
        }

        @media (max-width: 767px) {
          justify-self: center;
        }

        @media (max-width: 475px) {
          max-width: 240px;
        }
      }
    }

    article {
      max-width: 480px;

      @media (min-width: 2560px) {
        max-width: calc(480px * #{$zoom});
        font-size: calc(16px * 1.44);
      }

      @media (max-width: 767px) {        
        max-width: 520px;
        margin-bottom: 80px;
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 200px;
    }

    @media (max-width: 475px) {
      flex-direction: column;
      margin-bottom: 120px;
    }

    .main__how-to-use-image {
      transform: translateY(500px);
      transform-origin: 0 100%;
      opacity: 0;

      img {
        transform: scale(0.8);
        transition: transform 1s ease 1.5s;
      }
      
      @media (min-width: 2560px) {
        transform: translateY(calc(-500px * #{$zoom}))!important;
      }
    }

    .main__how-to-use-image-done {
      transform: translateY(0)!important;
      opacity: 1;
      transition: transform 1.5s ease, opacity 1.3s ease-in; 

      img {
        transform: scale(1);
        transition: transform 1s ease 1.5s;
      }
    }

    &.one {
      &::before {
        position: absolute;
        top: 6%;
        left: 62%;
        z-index: -1;
        width: 38%;
        height: 131%;
        background-image: url('../../../asset/resource/dahsed-title-line-two.png');
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100%;
        transform: rotate(247deg);
        content: '';
        transition: all 0.3s ease-in-out;

        @media (min-width: 2560px) {
          top: calc(6% * #{$zoom});
          left: calc(62% * #{$zoom});
          width: calc(38% * #{$zoom});
          height: calc(131% * #{$zoom});
        }

        @media screen and (max-width: 767px) {
          top: -44%;
          left: 16%;
          width: 100%;
          transform: rotate(250deg);
        }

        @media screen and (max-width: 475px) {
          width: 200%;
        }
      }
    }

    &.two {
      &::before {
        position: absolute;
        top: -74%;
        left: 54%;
        z-index: -1;
        width: 46%;
        height: 131%;
        background-image: url('../../../asset/resource/dahsed-title-line-two.png');
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100%;
        transform: rotate(194deg);
        content: '';
        transition: all 0.3s ease-in-out;

        @media (min-width: 2560px) {
          top: calc(-100% * #{$zoom});
          left: calc(35% * #{$zoom});
          width: calc(45% * #{$zoom});
          height: calc(131% * #{$zoom});
        }

        @media screen and (max-width: 767px) {
          top: -326px;
          left: 0;
          width: 150%;
          transform: rotate(196deg);
        }
      }

      &::after {
        position: absolute;
        top: 19%;
        left: -27%;
        z-index: -1;
        width: 46%;
        height: 131%;
        background-image: url('../../../asset/resource/dahsed-title-line-two.png');
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100%;
        transform: rotate(8deg);
        content: '';
        transition: all 0.3s ease-in-out;

        @media (min-width: 2560px) {
          top: calc(19% * #{$zoom});
          left: calc(-27% * #{$zoom});
          width: calc(46% * #{$zoom});
          height: calc(131% * #{$zoom});
        }

        @media screen and (max-width: 767px) {
          top: 40%;
          left: -75%;
          width: 150%;
          transform: rotate(20deg);
        }
      }
    }

    &.three {
      &::before {
        position: absolute;
        top: -64%;
        left: -4%;
        z-index: -1;
        width: 46%;
        height: 131%;
        background-image: url('../../../asset/resource/dahsed-title-line-two.png');
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100%;
        transform: rotate(65deg);
        content: '';
        transition: all 0.3s ease-in-out;

        @media (min-width: 2560px) {
          top: calc(-64% * #{$zoom});
          left: calc(-4% * #{$zoom});
          width: calc(46% * #{$zoom});
          height: calc(131% * #{$zoom});
        }

        @media screen and (max-width: 767px) {
          top: -37%;
          left: -23%;
          width: 150%;
          transform: rotate(70deg);
        }
      }
    }
  }

  &__use-cases {
    @extend %wrapper;
    max-width: 1340px;
    padding-bottom: 150px;
    overflow: hidden;

    @media (min-width: 2560px) {
      max-width: calc(1340px * #{$zoom});
      padding-bottom: calc(150px * #{$zoom});
    }

    @media screen and (max-width: 767px) {
      padding-bottom: 100px;
    }

    @media screen and (max-width: 475px) {
      padding-right: 0;
      padding-bottom: 70px;
      padding-left: 0;
    }

    h2 {
      @media screen and (max-width: 475px) {
        padding-right: 20px;
        padding-left: 20px;
      }
    }

    .carousel.carousel-slider {
      .control-dots {        
        .dot {
          width: 13px;
          height: 8px;
          border-radius: 0;

          @media (min-width: 2560px) {
            width: calc(13px * #{$zoom});
            height: calc(8px * #{$zoom});
          }

          &:hover {
            background: $light-blue;
          }

          &.selected {
            background: $light-blue;
          }
        }
      }

      .control-prev.control-arrow {
        &:before {
          width: 23px;
          height: 40px;
          background-image: url('../../../asset/resource/chevron.png');
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: 100%;
          border: none;

          @media (min-width: 2560px) {
            width: calc(23px * #{$zoom});
            height: calc(40px * #{$zoom});
          }
        }
      }

      .control-next.control-arrow {
        &:before {
          width: 23px;
          height: 40px;
          background-image: url('../../../asset/resource/chevron.png');
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: 100%;
          border: none;
          transform: rotate(180deg);

          @media (min-width: 2560px) {
            width: calc(23px * #{$zoom});
            height: calc(40px * #{$zoom});
          }
        }
      }

      .control-arrow {
        width: 33px;

        @media (min-width: 2560px) {
          width: calc(33px * #{$zoom});
        }
        
        @media (max-width: 640px) {
          display: none;
        }
      }
    } 
  }

  &__use-cases-slide {
    position: relative;
    display: flex;
    justify-content: space-between;
    max-width: 1220px;
    margin: 0 auto;
    padding: 0 40px 80px 50px;

    @media (min-width: 2560px) {
      max-width: calc(1190px * #{$zoom});
      height: 0 calc(40px * #{$zoom}) calc(80px * #{$zoom}) calc(50px * #{$zoom});
    }

    @media (max-width: 640px) {
      padding: 0 0 80px 0;
    }
    
    @media (min-width: 640px) and (max-width: 880px) {
      max-width: 95%;
    }

    article,
    figure {
      flex-basis: 45%;
      max-width: 475px;

      @media (min-width: 2560px) {
        max-width: calc(475px * #{$zoom});
      }
    }

    article {
      text-align: left;

      @media (max-width: 880px) {
        flex-basis: 100%;
        margin-top: 30%;
      }

      @media (max-width: 475px) {
        margin-top: 30%;
        padding-right: 20px;
        padding-left: 20px;
      }
    }

    figure {
      overflow: hidden;

      @media (max-width: 880px) {
        position: absolute;
        right: 5%;
        left: auto;
        z-index: -1;
        max-width: 75%;

        @media (max-width: 475px) {
          right: 0;
          max-width: 100%;
        }
      }

      img {
        filter: brightness(50%);

        @media (min-width: 1150px) {
          width: 625px!important;
        }

        @media (min-width: 880px) and (max-width: 1149px) {
          width: 125% !important;
        }
      }
    }

    span {
      padding-bottom: 35px;
      color: $light-blue;
      font-weight: 700;

      @media (min-width: 2560px) {
        padding-bottom: calc(35px * #{$zoom});
        font-size: calc(16px * #{$zoom});
      }
    }

    p {
      width: 90%;
      line-height: 24px;

      @media (min-width: 2560px) {
        font-size: calc(16px * #{$zoom});
        line-height: calc(24px * #{$zoom});
      }
    }

    ul {
      margin: 0;
      padding-top: 15px;
      padding-left: 20px;
      list-style-type: disc;

      @media (min-width: 2560px) {
        padding-top: calc(30px * #{$zoom});
        padding-left: calc(20px * #{$zoom});
      }

      li {
        margin-bottom: 15px;

        @media (min-width: 2560px) {
          margin-bottom: calc(15px * #{$zoom});
          font-size: calc(16px * #{$zoom});
        }
      }
    }
  }

  &__challenge {
    max-width: 1140px;
    margin: 0 auto;
    padding-bottom: 200px;

    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
      padding-bottom: calc(200px * #{$zoom});
    }

    @media (max-width: 767px) {
      padding-bottom: 150px;
    }

    @media (max-width: 475px) {
      padding-bottom: 100px;
    }

    article {
      max-width: 728px;

      @media (min-width: 2560px) {
        max-width: calc(728px * #{$zoom});
      }
  
      p {
        line-height: 24px;

        @media (min-width: 2560px) {
          font-size: calc(16px * #{$zoom});
          line-height: calc(24px * #{$zoom});
        }

        span {
          padding-bottom: 35px;
          color: $light-blue;
          font-weight: 700;
          font-size: 20px;
          line-height: 32px;
    
          @media (min-width: 2560px) {
            padding-bottom: calc(35px * #{$zoom});
            font-size: calc(20px * #{$zoom});
            line-height: calc(32px * #{$zoom});
          }
        }
      }

      .big-text {
        color: $white;
        font-weight: normal;
        font-size: 20px;

        @media (min-width: 2560px) {
          font-size: calc(20px * #{$zoom});
          line-height: calc(24px * #{$zoom});
        }
      }

      .small-text {
        color: $white;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;

        @media (min-width: 2560px) {
          font-size: calc(14px * #{$zoom});
          line-height: calc(20px * #{$zoom});
        }
      }
    }
  }

  &__challenge-results {
    max-width: 800px;
    margin: 0 auto;

    @media (min-width: 2560px) {
      max-width: calc(800px * #{$zoom});
    }
  }

  &__contact-us-form {
    @extend %wrapper;
    display: flex;
    justify-content: space-between;
    max-width: 1140px;

    @media (max-width: 1024px) {
      flex-direction: column;
    }

    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
    }
  }

  &__contact-us-form-title-col {
    flex-basis: 30%;
    max-width: 345px;

    @media (max-width: 1024px) {
      max-width: 100%;
      margin-bottom: 50px;
    }

    @media (min-width: 2560px) {
      flex-basis: calc(30% * #{$zoom});
      max-width: calc(345px * #{$zoom});
    }
  }

  &__form-wrapper {
    flex-basis: 65%;
    max-width: 685px;
    margin-bottom: 50px;

    @media (min-width: 2560px) {
      flex-basis: calc(65% * #{$zoom});
      max-width: calc(685px * #{$zoom});
    }

    .form-box {      
      &__row {
        display: flex;
        justify-content: space-between;

        &_without-flex {
          display: block;
        }

        @media (max-width: 475px) {
          display: block;
        }
      }

      &__col {
        flex-basis: 49%;
      }  
      
      &__description {
        font-size: 14px;

        a {
          text-decoration: underline;
          -webkit-text-decoration-color: $light-blue;
          text-decoration-color: $light-blue;
          background: linear-gradient(0deg, $light-blue, $light-blue);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        
        @media (min-width: 2560px) {
          font-size: calc(14px * #{$zoom});
          line-height: calc(18px * #{$zoom});
        }
      }
    }

    .form-input {
      &__text,
      &__textarea {
        &::-webkit-input-placeholder {
          color: $white;
          font-weight: 800;
          font-family: $basic-font;
          text-transform: none;
        }
    
        &::-moz-placeholder {
          color: $white;
          font-weight: 800;
          font-family: $basic-font;
          text-transform: none;
        }
    
        &:-moz-placeholder {
          color: $white;
          font-weight: 800;
          font-family: $basic-font;
          text-transform: none;
        }
    
        &:-ms-input-placeholder {
          color: $white;
          font-weight: 800;
          font-family: $basic-font;
          text-transform: none;
        }
      }

      &__textarea {
        min-height: 80px;

        @media (max-width: 475px) {
          min-height: 120px;
          margin-bottom: 30px;
        }
      }

      &__checkbox {
        @media (min-width: 767px) {
          max-width: 70%;
        }
      }

      &__checkbox-label {
        color: $white;

        &::before {
          color: $black;
          background-color: $white;
          border-color: $white;
        }

        &:hover, &:focus {
          &::before {
            color: $black;
            background-color: $light-blue;
            border-color: $light-blue;
          }
        }
      }
    }
  }

  &__contact-us-form-title {
    font-weight: 800;
    font-size: 60px;
    font-family: $secondary-font;
    line-height: 64px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (max-width: 475px) {
      font-size: 40px;
      line-height: 48px;
    }
  }

  &__form-title {
    margin-bottom: 10px;
    font-weight: 800;
    font-size: 32px;
    font-family: $secondary-font;
    line-height: 36px;
    text-transform: uppercase;

    @media (max-width: 475px) {
      font-size: 24px;
      line-height: 32px;
    }

    @media (min-width: 2560px) {
      font-size: calc(32px * #{$zoom});
      line-height: calc(36px * #{$zoom});
    }
  }

  &__form-description {
    margin-bottom: 50px;
    
    @media (min-width: 2560px) {
      font-size: calc(16px * #{$zoom});
      line-height: calc(20px * #{$zoom});
    }
  }

  &__form-button {
    @extend %button-colored;
    width: 100%;
    max-width: 376px;
    height: 48px;
    margin-left: 0;
    line-height: 48px;

    @media (min-width: 2560px) {
      max-width: calc(376px * #{$zoom});
      height: calc(48px * #{$zoom});
      font-size: calc(18px * #{$zoom});    
      line-height: calc(48px * #{$zoom});   
    }
  }

  &__success {
    margin-bottom: 300px;

    @media (min-width: 2560px) {
      margin-bottom: calc(300px * #{$zoom});
    }

    @media (max-width: 1024px) {
      margin-top: 100px;
      margin-bottom: 150px;
    }

    @media (max-width: 475px) {
      text-align: center;
    }
  } 
}

.prize {
  padding: 35px 0;
  font-size: 34px!important;
  line-height: 46px!important;
}